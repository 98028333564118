<template>
	<v-sheet outlined>
		<v-toolbar flat dense :color="bgColor">
			<v-toolbar-title class="text-overline">Labels</v-toolbar-title>
			<v-spacer />
			<v-toolbar-items>
				<v-menu
					bottom
					left
					tile
					offset-y
					:close-on-content-click="closeOnContentClick"
					:z-index="zIndex"
					v-model="showMenu"
				>
					<template v-slot:activator="{ on: menu, attrs }">
						<v-btn v-bind="attrs" v-on="{ ...menu }" icon
							><v-icon>mdi-plus-circle-outline</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item-group multiple v-model="selection">
							<v-list-item
								v-for="(label, index) in labels"
								:key="index"
								:value="label.label_id"
							>
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox
											:input-value="active"
										></v-checkbox>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>{{
											label.label_name
										}}</v-list-item-title>
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list-item-group>
						<v-divider class="mb-2" />
						<v-btn
							block
							text
							@click.stop="addLabels"
							:loading="loading"
							>Toevoegen</v-btn
						>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-toolbar>
		<v-divider />
		<v-simple-table>
			<template v-slot:default>
				<tbody>
					<tr v-for="rowObj in rowObject" :key="rowObj.label_id">
						<td>
							<v-chip label outlined>
								{{ rowObj.label_name }}
							</v-chip>
						</td>
						<td style="width: 36px">
							<v-btn
								icon
								@click="deleteLabel(rowObj)"
								class="mr-1"
								><v-icon small>mdi-delete</v-icon></v-btn
							>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</v-sheet>
</template>
<script>
	import { mapGetters } from "vuex";
	import caas from "@/services/caas";
	export default {
		name: "CatalogItemLabel",
		data() {
			return {
				loading: false,
				closeOnContentClick: false,
				showMenu: false,
				selection: [],
				zIndex: 1,
			};
		},
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItemLabel",
				labels: "catalogItem/labels",
				bgColor: "session/bgColor",
				itemId: "catalogItem/catalogItemId",
			}),
		},
		methods: {
			addLabels() {
				const sdoName = "web/objects/dEcatItemLabel.r";
				const params = {
					action: "create",
					rowObjUpd: [],
				};
				for (let i = 0; i < this.selection.length; i++) {
					let obj = {
						item_id: this.itemId,
						label_id: Number(this.selection[i]),
						RowNum: i,
						RowMod: "A",
					};
					params.rowObjUpd.push(obj);
				}
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess").then(() => {
								this.$store.dispatch("catalogItem/fetchItemLabels");
							});
						}
						if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.selection = [];
						this.showMenu = false;
					});
			},
			deleteLabel(_rowObject) {
				const sdoName = "web/objects/dEcatItemLabel.r";
				const params = {
					action: "delete",
					rowObjUpd: [],
				};
				_rowObject.RowMod = "D";
				params.rowObjUpd.push(_rowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess").then(() => {
								this.$store.dispatch("catalogItem/fetchItemLabels");
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
		mounted() {
			this.$store.dispatch("catalogItem/fetchLabels");
		},
	};
</script>