
<template>
	<v-container>
		<v-row justify="center">
			<v-col
				cols="auto"
				v-for="(_rowObject, img_id) of rowObject"
				:key="img_id"
			>
				<v-card
					flat
					v-on:click.stop="openDialog(_rowObject)"
					class="ma-3"
				>
					<v-card-title>
						<v-sheet outlined>
							<v-img
								position="center center"
								:src="_rowObject.img_tn_url"
								width="150"
								height="150"
							/>
						</v-sheet>
					</v-card-title>
					<v-card-subtitle class="text-center">
						{{ _rowObject.item_seqnr }}
					</v-card-subtitle>
				</v-card>
			</v-col>
		</v-row>
		<v-row :class="bgColor" align="center" justify="center">
			<v-col cols="auto">
				<v-file-input
					:loading="loading"
					v-model="file"
					prepend-icon="mdi-camera"
					show-size
					flat
					dense
					solo
					label="Afbeelding uploaden"
					hide-details
					background-color="transparent"
				></v-file-input
			></v-col>
			<v-col cols="auto" v-if="file">
				<v-btn :loading="loading" text plain v-on:click="uploadFile"
					>Uploaden</v-btn
				>
			</v-col>
		</v-row>
		<!-- DIALOG -->
		<v-dialog v-model="dialog">
			<v-card class="mx-auto" width="600px" max-width="100%">
				<v-card-title>
					Foto {{ currentRowObject.img_id }}
					<v-spacer />
					<v-btn icon v-on:click="dialog = false"
						><v-icon>mdi-close-circle</v-icon></v-btn
					>
				</v-card-title>
				<v-card-text>
					<v-img
						:src="currentRowObject.img_url"
						contain
						position="center center"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions :class="bgColor">
					<v-btn
						icon
						plain
						@click="dialogDelete = true"
						:loading="loading"
						><v-icon left>mdi-delete</v-icon></v-btn
					>
					<v-spacer />
					<v-spacer />
					<v-spacer />
					<v-text-field
						v-model="currentRowObject.item_seqnr"
						type="Number"
						label="Volgnr"
						:loading="loading"
						:disabled="!updateActive"
						@input="dataModified = true"
					/>
					<v-btn
						icon
						color="primary"
						:disabled="updateActive"
						v-on:click.stop="beginUpdate"
						><v-icon>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn
						icon
						v-show="updateActive"
						:disabled="!dataModified"
						v-on:click.stop="saveChanges"
						color="green"
						><v-icon>mdi-check-outline</v-icon></v-btn
					>
					<v-btn
						icon
						color="red"
						v-show="updateActive"
						@click.stop="cancelUpdate"
						><v-icon>mdi-close</v-icon></v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title
					><span class="text-center"
						>Je gaat deze afbeelding verwijderen</span
					></v-card-title
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="detachImage">Bevestigen</v-btn>
					<v-btn text @click="dialogDelete = false">Annuleren</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "CatalogItemImage",
		data() {
			return {
				currentRowObject: {},
				currentRowObjectBefore: {},
				dialog: false,
				dialogDelete: false,
				loading: false,
				file: null,
				dataModified: false,
				updateActive: false,
			};
		},
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItemImg",
				itemId: "catalogItem/catalogItemId",
				bgColor: "session/bgColor",
			}),
		},
		methods: {
			openDialog(_rowObject) {
				Object.assign(this.currentRowObjectBefore, _rowObject);
				this.currentRowObject = _rowObject;
				this.dialog = true;
			},
			uploadFile() {
				const that = this;
				const fileReader = new FileReader();
				fileReader.onload = function () {
					let params = {};
					params.itemId = Number(that.itemId);
					params.fileName = that.file.name;
					params.b64 = fileReader.result.replace(/^data:.+;base64,/, "");
					caas.rpc("createImage", params)
						.then((response) => {
							console.log(response);
							if (response.data.success) {
								that.$store.dispatch("session/showSuccess");
								that.$store.dispatch("catalogItem/fetchItemImages");
							} else if (response.data.error) {
								this.$store.dispatch(
									"session/showBottomMessage",
									response.data.error
								);
							}
						})
						.catch((error) => {})
						.finally(() => {
							that.file = null;
							that.loading = false;
						});
				};
				this.loading = true;
				fileReader.readAsDataURL(this.file);
			},
			detachImage() {
				let params = {
					itemId: this.currentRowObject.item_id,
					imageId: this.currentRowObject.img_id,
				};
				this.loading = true;
				caas.rpc("detachImage", params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess");
							this.$store.dispatch("catalogItem/fetchItemImages");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.dialogDelete = false;
						this.dialog = false;
					});
			},
			beginUpdate() {
				this.updateActive = true;
			},
			cancelUpdate() {
				this.dataModified = false;
				this.updateActive = false;
				Object.assign(this.currentRowObject, this.currentRowObjectBefore);
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatItemImg.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.currentRowObject.RowMod = "U";
				params.rowObjUpd.push(this.currentRowObject);
				params.rowObjUpd.push(this.currentRowObjectBefore);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("catalogItem/fetchItemImages");
							this.$store.dispatch("session/showSuccess");
							this.updateActive = false;
							this.dataModified = false;
							this.currentRowObjectBefore = {};
							this.currentRowObject.RowMod = "";
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
						this.dialog = false;
					});
			},
		},
	};
</script>