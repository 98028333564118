<template>
	<v-expansion-panel>
		<v-expansion-panel-header class="py-0">
			<v-toolbar flat dense>
				<v-toolbar-title
					class="text-overline"
					:style="{
						color: this.rowObject.option_value_code1 || 'black',
					}"
				>
					{{ this.rowObject.c_option_value }}
				</v-toolbar-title>
				<v-toolbar-items>
					<v-icon
						color="warning"
						v-if="this.rowObject.is_visible_website === false"
						class="ml-3"
						>mdi-cloud-off-outline</v-icon
					>
				</v-toolbar-items>
				<v-spacer />
				<v-toolbar-items v-if="isExpanded">
					<v-btn
						icon
						color="primary"
						:disabled="updateActive"
						v-on:click.stop="beginUpdate()"
						><v-icon>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn
						icon
						:disabled="!dataModified"
						v-on:click.stop="saveChanges()"
						color="green"
						><v-icon>mdi-check-outline</v-icon></v-btn
					>
					<v-btn
						icon
						color="red"
						:disabled="!updateActive"
						@click.stop="cancelUpdate()"
						><v-icon>mdi-close</v-icon></v-btn
					>
					<v-btn
						icon
						:disabled="updateActive"
						@click.stop="openDialogDeleteItemSku()"
						><v-icon>mdi-delete-outline</v-icon></v-btn
					>
				</v-toolbar-items>
				<v-spacer />
			</v-toolbar>
		</v-expansion-panel-header>
		<v-divider />
		<v-expansion-panel-content v-if="this.rowObject">
			<v-form :readonly="!updateActive">
				<v-row class="mt-1" justify="space-between">
					<v-col cols="12" md="2">
						<v-card v-if="Number(this.rowObject.img_id) > 0" flat>
							<v-img
								:src="this.rowObject.img_tn_url"
								height="150"
								width="150"
								contain
								position="center center"
							/>
							<v-card-actions>
								<v-btn
									icon
									plain
									@click="openDialogDetachImage()"
								>
									<v-icon size="20">mdi-delete</v-icon>
								</v-btn></v-card-actions
							>
						</v-card>
						<v-card v-else flat :loading="loading">
							<v-file-input
								:loading="loading"
								v-model="file"
								prepend-icon="mdi-camera"
								flat
								hide-details
								hide-input
								@change="uploadFile()"
							/>
						</v-card>
					</v-col>
					<v-col cols="12" md="7">
						<v-row>
							<v-col cols="12" md="6">
								<v-text-field
									readonly
									hide-details="auto"
									v-model="rowObject.item_seq_nr"
									label="Volgnr"
									type="Number"
								/>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field
									readonly
									hide-details="auto"
									v-model="rowObject.item_sku"
									label="Code"
								/>
							</v-col>
						</v-row>

						<v-row v-if="itemHasColors">
							<v-col cols="12" md="6">
								<v-text-field
									hide-details="auto"
									v-model="rowObject.option_value1"
									label="Kleur"
									@input="dataModified = true"
								/>
							</v-col>
							<v-col cols="12" md="6">
								<v-autocomplete
									hide-details="auto"
									v-model="rowObject.option_value_code1"
									label="Kleur code"
									:items="colorCodes"
									@input="dataModified = true"
								>
									<template v-slot:item="data">
										<span
											:style="{
												backgroundColor:
													data.item.value,
											}"
										>
											&nbsp; &nbsp; &nbsp;
										</span>
										<span class="ml-2">
											{{ data.item.text }}
										</span>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row v-if="itemHasSizes">
							<v-col cols="12">
								<v-text-field
									hide-details="auto"
									v-model="rowObject.option_value2"
									label="Maat"
									@input="dataModified = true"
								/>
							</v-col>
						</v-row>

						<v-row v-if="itemHasVariants">
							<v-col cols="12">
								<v-text-field
									hide-details="auto"
									v-model="rowObject.option_value3"
									label="Variant"
									@input="dataModified = true"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" md="6">
								<v-text-field
									hide-details="auto"
									v-model="rowObject.end_user_price"
									label="Prijs"
									type="Number"
									@input="dataModified = true"
								/>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field
									hide-details="auto"
									v-model="rowObject.promo_price"
									label="Promo prijs"
									type="Number"
									@input="dataModified = true"
								/>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12" md="2">
						<v-card flat>
							<v-checkbox
								label="website"
								v-model="rowObject.is_visible_website"
								@change="dataModified = true"
							/>
							<v-checkbox
								label="nieuw"
								v-model="rowObject.is_new"
								@change="dataModified = true"
							/>
							<v-checkbox
								label="promo"
								v-model="rowObject.is_promo"
								@change="dataModified = true"
							/>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
			<v-dialog v-model="dialogDetachImage" width="auto">
				<v-card>
					<v-card-title class="text-subtitle-1">
						Afbeelding verwijderen?
					</v-card-title>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn text color="primary" @click="detachImage()"
							>Verwijderen</v-btn
						>
						<v-btn text plain @click="dialogDetachImage = false"
							>Annuleren</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialogDeleteItemSku" width="auto">
				<v-card>
					<v-card-title class="text-subtitle-1">
						{{ this.rowObject.item_sku }} &nbsp;
						<span>: verwijderen? </span>
					</v-card-title>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn text color="primary" @click="deleteItemSku()"
							>Verwijderen</v-btn
						>
						<v-btn text plain @click="dialogDeleteItemSku = false"
							>Annuleren</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-expansion-panel-content>
		<v-divider />
	</v-expansion-panel>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "CatalogItemSkuSku",
		props: {
			isExpanded: Boolean,
			colorCodes: Array,
			rowObjectBefore: null,
		},
		data() {
			return {
				dialogDetachImage: false,
				dialogDeleteItemSku: false,
				updateActive: false,
				dataModified: false,
				file: {},
				loading: false,
			};
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
				itemId: "catalogItem/catalogItemId",
				itemHasColors: "catalogItem/itemHasColors",
				itemHasSizes: "catalogItem/itemHasSizes",
				itemHasVariants: "catalogItem/itemHasVariants",
			}),
			rowObject() {
				return JSON.parse(JSON.stringify(this.rowObjectBefore));
			},
		},
		methods: {
			deleteItemSku() {
				let sdoName = "web/objects/dECatItemSku.r",
					params = {
						action: "delete",
						rowObjUpd: [],
					};
				this.rowObject.RowMod = "D";
				params.rowObjUpd.push(this.rowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("catalogItem/fetchItemSkuList");
							this.$store.dispatch("session/showSuccess");
							this.rowObject = {};
							this.dialogDeleteItemSku = false;
						} else if (result.data.error) throw result.data.error;
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			openDialogDeleteItemSku() {
				this.dialogDeleteItemSku = true;
			},
			openDialogDetachImage() {
				this.dialogDetachImage = true;
			},
			beginUpdate() {
				this.updateActive = true;
			},
			cancelUpdate() {
				this.updateActive = false;
				this.dataModified = false;
				this.rowObject = JSON.parse(JSON.stringify(this.rowObjectBefore));
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatItemSku.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.rowObject.RowMod = "U";
				params.rowObjUpd.push(this.rowObject);
				params.rowObjUpd.push(this.rowObjectBefore);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.updateActive = false;
							this.dataModified = false;
							this.rowObject.rowMod = "";
							this.$store
								.dispatch("catalogItem/fetchItemSkuList")
								.then(() => {
									this.$store.dispatch("session/showSuccess");
								});
						} else if (result.data.error) {
							throw result.data.error.message;
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			detachImage() {
				let params = {
					itemId: this.rowObject.item_id,
					itemSku: this.rowObject.item_sku,
					imageId: this.rowObject.img_id,
				};
				this.loading = true;
				caas.rpc("detachImage", params)
					.then((response) => {
						if (response.data.success) {
							this.rowObject.img_tn_url = "";
							this.rowObject.img_id = 0;
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.dialogDetachImage = false;
					});
			},
			uploadFile() {
				if (!this.file) return;
				const that = this;
				const fileReader = new FileReader();
				fileReader.onload = function () {
					let params = {};
					params.itemId = Number(that.rowObject.item_id);
					params.itemSku = that.rowObject.item_sku;
					params.fileName = that.file.name;
					params.b64 = fileReader.result.replace(/^data:.+;base64,/, "");
					caas.rpc("createImage", params)
						.then((response) => {
							if (response.data.success) {
								that.rowObject.img_id =
									response.data.success.imageId;
								that.rowObject.img_tn_url =
									response.data.success.imageThumbUrl;
								that.$store.dispatch("session/showSuccess");
							} else if (response.data.error) {
								that.$store.dispatch(
									"session/showBottomMessage",
									response.data.error
								);
							}
						})
						.finally(() => {
							that.file = null;
							that.loading = false;
						});
				};
				this.loading = true;
				fileReader.readAsDataURL(this.file);
			},
		},
	};
</script>