<template>
	<v-sheet outlined>
		<v-toolbar flat dense :color="bgColor">
			<v-toolbar-title class="text-overline">Attributen</v-toolbar-title>
			<v-spacer />
			<v-toolbar-items>
				<v-menu
					bottom
					left
					tile
					offset-y
					:close-on-content-click="closeOnContentClick"
					:z-index="zIndex"
					v-model="showMenu"
				>
					<template v-slot:activator="{ on: menu, attrs }">
						<v-btn v-bind="attrs" v-on="{ ...menu }" icon
							><v-icon>mdi-plus-circle-outline</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item-group multiple v-model="selection">
							<v-list-item
								v-for="(attr, index) in attributes"
								:key="index"
								:value="attr.attr_id"
							>
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox
											:input-value="active"
										></v-checkbox>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>{{
											attr.attr_name
										}}</v-list-item-title>
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list-item-group>
						<v-divider class="mb-2" />
						<v-btn
							block
							text
							@click="addAttributes"
							:loading="loading"
							>Toevoegen</v-btn
						>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-toolbar>
		<v-divider />
		<v-simple-table>
			<template v-slot:default>
				<tbody>
					<tr v-for="rowObj in rowObject" :key="rowObj.attr_id">
						<td style="width: 150px" class="text-subtitle-2">
							{{ rowObj.attr_name }}
						</td>
						<td>
							<v-textarea
								rows="1"
								auto-grow
								:value="rowObj.attr_value"
								hide-details
								solo
								flat
								readonly
								@click="beginUpdate(rowObj)"
							/>
						</td>
						<td style="width: 40px">
							<v-btn
								icon
								@click="deleteAttribute(rowObj)"
								class="mr-1"
								><v-icon small>mdi-delete</v-icon></v-btn
							>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		<v-dialog v-model="updateActive">
			<v-card flat width="500px" max-width="100%" class="mx-auto">
				<v-card-title> </v-card-title>
				<v-card-text class="mt-3">
					<v-textarea
						v-model="currentRowObject.attr_value"
						:label="currentRowObject.attr_name"
						rows="1"
						auto-grow
						outlined
						hide-details
					/>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn icon v-on:click.stop="saveChanges" color="primary"
						><v-icon>mdi-check-outline</v-icon></v-btn
					>
					<v-btn icon color="red" v-on:click.stop="cancelUpdate"
						><v-icon>mdi-close</v-icon></v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	export default {
		name: "CatalogItemAttr",
		data() {
			return {
				closeOnContentClick: false,
				loading: false,
				showMenu: false,
				selection: [],
				zIndex: 1,
				updateActive: false,
				currentRowObjectBefore: {},
				currentRowObject: {},
			};
		},
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItemAttr",
				attributes: "catalogItem/attributes",
				bgColor: "session/bgColor",
				itemId: "catalogItem/catalogItemId",
			}),
		},
		methods: {
			addAttributes() {
				const sdoName = "web/objects/dEcatItemAttr.r";
				const params = {
					action: "create",
					rowObjUpd: [],
				};
				for (let i = 0; i < this.selection.length; i++) {
					let obj = {
						item_id: this.itemId,
						attr_id: Number(this.selection[i]),
						RowNum: i + 1,
						RowMod: "A",
					};
					params.rowObjUpd.push(obj);
				}
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess").then(() => {
								this.$store.dispatch(
									"catalogItem/fetchItemAttributes"
								);
							});
						}
					})
					.finally(() => {
						this.loading = false;
						this.selection = [];
						this.showMenu = false;
					});
			},
			deleteAttribute(_rowObject) {
				const sdoName = "web/objects/dEcatItemAttr.r";
				const params = {
					action: "delete",
					rowObjUpd: [],
				};
				_rowObject.RowMod = "D";
				params.rowObjUpd.push(_rowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess").then(() => {
								this.$store.dispatch(
									"catalogItem/fetchItemAttributes"
								);
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			beginUpdate(_rowObject) {
				this.updateActive = true;
				Object.assign(this.currentRowObjectBefore, _rowObject);
				this.currentRowObject = _rowObject;
			},
			cancelUpdate() {
				this.updateActive = false;
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatItemAttr.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.currentRowObject.RowMod = "U";
				params.rowObjUpd.push(this.currentRowObject);
				params.rowObjUpd.push(this.currentRowObjectBefore);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("session/showSuccess");
							this.$store
								.dispatch("catalogItem/fetchItemAttributes")
								.then(() => {
									this.updateActive = false;
								});
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
		mounted() {
			this.$store.dispatch("catalogItem/fetchAttributes");
		},
	};
</script>