<template>
	<div>
		<!-- TOOLBAR ADD/SORT -->
		<v-toolbar flat dense :color="bgColor"
			><v-spacer />
			<v-toolbar-items>
				<v-btn icon @click="dialogAddItemSku = true"
					><v-icon>mdi-plus-circle-outline</v-icon></v-btn
				>
				<v-btn icon @click="dialogSortItemSku = true"
					><v-icon>mdi-sort-variant</v-icon>
				</v-btn>
			</v-toolbar-items>
			<v-spacer />
		</v-toolbar>
		<v-divider />
		<!-- DIALOG ADD -->
		<v-dialog v-model="dialogAddItemSku">
			<v-card width="500" max-width="100%" class="mx-auto">
				<v-card-title> nieuw product </v-card-title>
				<v-card-text>
					<v-text-field
						v-model="newItemSku"
						label="Code"
						hide-details="auto"
						hint="geef de code van het nieuwe product"
						counter
						maxlength="20"
						clearable
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						plain
						color="primary"
						:disabled="!newItemSku"
						@click="addItemSku()"
					>
						toevoegen
					</v-btn>
					<v-spacer />
					<v-btn text plain @click="dialogAddItemSku = false">
						annuleren
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- DIALOG SORT -->
		<v-dialog v-model="dialogSortItemSku">
			<v-card width="500" max-width="100%" class="mx-auto">
				<v-card-title>
					<span>sorteren</span> <v-spacer />
					<v-icon @click="cancelSort()">mdi-close-circle</v-icon>
				</v-card-title>
				<v-divider />
				<v-list style="max-height: 75vh; overflow-y: auto">
					<draggable v-model="rowObject" @change="beginSort">
						<v-list-item
							dense
							v-for="_rowObject in rowObject"
							:key="_rowObject.RowNum"
						>
							<v-btn icon
								><v-icon
									>mdi-drag-horizontal-variant</v-icon
								></v-btn
							>
							<span class="mx-3">{{
								_rowObject.c_option_value
							}}</span>
							<v-icon
								color="warning"
								v-if="_rowObject.is_visible_website === false"
								>mdi-cloud-off-outline</v-icon
							>
						</v-list-item>
					</draggable>
				</v-list>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						color="primary"
						:disabled="!sortActive"
						@click="sortItemSku()"
					>
						bevestigen
					</v-btn>
					<v-spacer />
					<v-btn
						text
						plain
						:disabled="!sortActive"
						@click="cancelSort()"
					>
						annuleren
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- ITEM SKU's -->
		<v-expansion-panels
			flat
			accordion
			v-model="currentPanel"
			style="max-height: 60vh; overflow-y: auto"
		>
			<catalog-item-sku-sku
				v-for="_rowObject of rowObject"
				:key="_rowObject.RowNum"
				:isExpanded="_rowObject.RowNum === currentPanel + 1"
				:colorCodes="colorCodes"
				:rowObjectBefore="_rowObject"
			/>
		</v-expansion-panels>
	</div>
</template>
<script>
	import draggable from "vuedraggable";
	import caas from "@/services/caas";
	import CatalogItemSkuSku from "@/components/CatalogItemSkuSku";
	import { mapGetters } from "vuex";
	export default {
		name: "CatalogItemSku",
		components: { CatalogItemSkuSku, draggable },
		data() {
			return {
				colorCodes: [],
				dialogAddItemSku: false,
				dialogSortItemSku: false,
				newItemSku: "",
				currentPanel: -1,
				loading: false,
				sortActive: false,
				rowObject: [],
			};
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
				rowObjectBefore: "catalogItem/getItemSkuList",
			}),
		},
		watch: {
			rowObjectBefore(newValue, oldValue) {
				if (newValue != oldValue) {
					this.rowObject = JSON.parse(JSON.stringify(newValue));
					//this.currentPanel = -1;
				}
			},
		},
		methods: {
			beginSort() {
				this.sortActive = true;
			},
			cancelSort() {
				this.rowObject = JSON.parse(JSON.stringify(this.rowObjectBefore));
				this.sortActive = false;
				this.dialogSortItemSku = false;
			},
			addItemSku() {
				const sdoName = "web/objects/dECatItemSku.r";
				let params = {
					action: "create",
					rowObjUpd: [],
				};
				let x = {
					RowMod: "A",
					item_id: this.itemId,
					item_sku: this.newItemSku,
					is_visible_website: false,
				};
				params.rowObjUpd.push(x);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("catalogItem/fetchItemSkuList");
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.dialogAddItemSku = false;
						this.newItemSku = null;
					});
			},
			sortItemSku() {
				this.loading = true;
				let that = this;
				let sdoName = "web/objects/dECatItemSku.r";
				let params = {
					action: "update",
					rowObjUpd: [],
				};
				for (let idx = 0; idx < that.rowObject.length; idx++) {
					// before row
					let beforeRecord = that.rowObjectBefore.find(
						(_rowObjectBefore) => {
							return (
								_rowObjectBefore.RowNum ===
								that.rowObject[idx].RowNum
							);
						}
					);
					if (beforeRecord.item_seq_nr != idx + 1) {
						params.rowObjUpd.push(beforeRecord);
						// changed row
						that.rowObject[idx].RowMod = "U";
						that.rowObject[idx].item_seq_nr = idx + 1;
						params.rowObjUpd.push(that.rowObject[idx]);
					}
				}
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.sortActive = false;
							this.dialogSortItemSku = false;
							this.$store.dispatch("catalogItem/fetchItemSkuList");
							this.$store.dispatch("session/showSuccess");
						}
						if (response.data.error) {
							throw response.data.error;
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
		deactivated() {
			this.currentPanel = -1;
		},
		mounted() {
			this.rowObject = JSON.parse(JSON.stringify(this.rowObjectBefore));
			caas.rpc("getCssColors", {}).then(
				(response) =>
					(this.colorCodes = response.data.success.data.cssColors)
			);
		},
	};
</script>