<style scoped>
	.kmp {
		max-height: 500px;
		overflow-y: auto;
	}
</style>
<template>
	<v-card>
		<v-expansion-panels flat accordion v-model="currentSection">
			<!-- SECTION 1 -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Beschrijving 1
						</v-toolbar-title>
						<v-toolbar-items
							v-if="currentSection === 0"
							class="ml-10"
						>
							<v-btn
								icon
								color="primary"
								:disabled="updateActive"
								v-on:click.stop="beginUpdate"
								><v-icon>mdi-pencil-outline</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="!dataModified"
								v-on:click.stop="saveChanges"
								color="green"
								><v-icon>mdi-check-outline</v-icon></v-btn
							>
							<v-btn
								icon
								color="red"
								:disabled="!updateActive"
								v-on:click.stop="cancelUpdate"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</v-toolbar-items>
					</v-toolbar>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-card-text>
						<!-- TITLE 1 -->
						<v-row dense align="center">
							<v-col cols="12" md="2" class="font-weight-medium">
								Titel
							</v-col>
							<v-col cols="12" md="10">
								<v-text-field
									v-model="rowObject.title_1"
									:disabled="!updateActive"
									@change="onDataModified"
								/>
							</v-col>
						</v-row>
						<!-- TEXT 1 -->
						<v-row dense>
							<v-col cols="12" md="2" class="font-weight-medium">
								Tekst
							</v-col>
							<v-col cols="12" md="10">
								<v-sheet
									v-if="!updateActive"
									v-html="rowObject.text_1"
									class="kmp"
								/>
								<vue-editor
									v-else
									class="kmp"
									v-model="rowObject.text_1"
									v-on:text-change="onDataModified"
									:editorToolbar="customToolbar"
								></vue-editor>
							</v-col>
						</v-row>
						<!-- IMAGE 1 -->
						<v-row>
							<v-col cols="12" md="2" class="font-weight-medium">
								Afbeelding
							</v-col>
							<v-col cols="12" md="4" lg="2">
								<v-card
									flat
									v-on:click="openDialog"
									:readonly="!updateActive"
								>
									<v-sheet
										outlined
										v-if="rowObject.img_url_1"
									>
										<v-img
											:src="rowObject.img_url_1"
											contain
											height="150"
											position="center center"
										/>
									</v-sheet>
									<v-card-actions v-if="updateActive">
										<v-btn
											v-if="!rowObject.img_url_1"
											icon
											plain
											@click.stop="dialog = true"
											><v-icon size="20"
												>mdi-plus-circle</v-icon
											></v-btn
										>
										<v-btn
											v-else
											icon
											plain
											@click.stop="detachImage"
											><v-icon size="20"
												>mdi-delete</v-icon
											></v-btn
										>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-expansion-panel-content>
				<v-divider />
			</v-expansion-panel>
			<!-- SECTION 2 -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Beschrijving 2
						</v-toolbar-title>
						<v-toolbar-items
							v-if="currentSection === 1"
							class="ml-10"
						>
							<v-btn
								icon
								color="primary"
								:disabled="updateActive"
								v-on:click.stop="beginUpdate"
								><v-icon>mdi-pencil-outline</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="!dataModified"
								v-on:click.stop="saveChanges"
								color="green"
								><v-icon>mdi-check-outline</v-icon></v-btn
							>
							<v-btn
								icon
								color="red"
								:disabled="!updateActive"
								v-on:click.stop="cancelUpdate"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-card-text>
						<!-- TITLE 2 -->
						<v-row dense align="center">
							<v-col cols="12" md="2" class="font-weight-medium">
								Titel
							</v-col>
							<v-col cols="12" md="10">
								<v-text-field
									v-model="rowObject.title_2"
									:disabled="!updateActive"
									@change="onDataModified"
								/>
							</v-col>
						</v-row>
						<!-- TEXT 2 -->
						<v-row dense>
							<v-col cols="12" md="2" class="font-weight-medium">
								Tekst
							</v-col>
							<v-col cols="12" md="10">
								<v-sheet
									v-if="!updateActive"
									v-html="rowObject.text_2"
									class="kmp"
								/>
								<vue-editor
									v-else
									class="kmp"
									v-model="rowObject.text_2"
									@change="onDataModified"
									:editorToolbar="customToolbar"
								></vue-editor>
							</v-col>
						</v-row>
						<!-- IMAGE 2 -->
						<v-row>
							<v-col cols="12" md="2" class="font-weight-medium">
								Afbeelding
							</v-col>
							<v-col cols="12" md="4" lg="2">
								<v-card
									flat
									v-on:click="openDialog"
									:disabled="!updateActive"
								>
									<v-sheet
										outlined
										v-if="rowObject.img_url_2"
									>
										<v-img
											:src="rowObject.img_url_2"
											contain
											height="150"
											position="center center"
										/>
									</v-sheet>
									<v-card-actions v-if="updateActive">
										<v-btn
											v-if="!rowObject.img_url_2"
											icon
											plain
											><v-icon size="20"
												>mdi-plus-circle</v-icon
											></v-btn
										>
										<v-btn
											v-else
											plain
											icon
											@click.stop="detachImage"
											><v-icon size="20"
												>mdi-delete</v-icon
											></v-btn
										>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-expansion-panel-content>
				<v-divider />
			</v-expansion-panel>
			<!-- SECTION 3 - MAMZEL's KEUZE -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Mamzel's keuze

							<v-icon v-if="rowObject.img_url_3" class="ml-3"
								>mdi-star-outline</v-icon
							>
						</v-toolbar-title>
						<v-toolbar-items
							v-if="currentSection === 2"
							class="ml-10"
						>
							<v-btn
								icon
								color="primary"
								:disabled="updateActive"
								v-on:click.stop="beginUpdate"
								><v-icon>mdi-pencil-outline</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="!dataModified"
								v-on:click.stop="saveChanges"
								color="green"
								><v-icon>mdi-check-outline</v-icon></v-btn
							>
							<v-btn
								icon
								color="red"
								:disabled="!updateActive"
								v-on:click.stop="cancelUpdate"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-expansion-panel-header>
				<!-- MAMZEL's KEUZE -->
				<v-expansion-panel-content>
					<v-card-text>
						<!-- TITLE -->
						<v-row dense align="center">
							<v-col cols="12" md="2" class="font-weight-medium">
								Auteur
							</v-col>
							<v-col cols="12" md="10">
								<v-text-field
									v-model="rowObject.title_3"
									:disabled="!updateActive"
									@change="onDataModified"
								/>
							</v-col>
						</v-row>
						<!-- TEXT -->
						<v-row dense>
							<v-col cols="12" md="2" class="font-weight-medium">
								Tekst
							</v-col>
							<v-col cols="12" md="10">
								<v-sheet
									v-if="!updateActive"
									v-html="rowObject.text_3"
									class="kmp"
								/>
								<vue-editor
									v-else
									class="kmp"
									v-model="rowObject.text_3"
									@change="onDataModified"
									:editorToolbar="customToolbar"
								></vue-editor>
							</v-col>
						</v-row>
						<!-- IMAGE -->
						<v-row>
							<v-col cols="12" md="2" class="font-weight-medium">
								Afbeelding
							</v-col>
							<v-col cols="12" md="4" lg="2">
								<v-card
									v-if="rowObject.img_url_3"
									flat
									v-on:click="openDialog"
									:disabled="!updateActive"
								>
									<v-card-title>
										<v-img
											:src="rowObject.img_url_3"
											contain
											height="150"
											position="center center"
										/>
									</v-card-title>
									<v-card-actions v-if="updateActive">
										<v-btn
											icon
											plain
											@click.stop="detachImage"
											><v-icon size="20"
												>mdi-delete</v-icon
											></v-btn
										>
									</v-card-actions>
								</v-card>
								<v-card v-else flat :loading="loading">
									<v-file-input
										:loading="loading"
										:disabled="!updateActive"
										v-model="file"
										prepend-icon="mdi-camera"
										flat
										hide-details
										hide-input
										@change="uploadFile()"
									/>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-expansion-panel-content>
				<v-divider />
			</v-expansion-panel>
			<!-- SECTION 4: VIDEO -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Video
						</v-toolbar-title>
						<v-toolbar-items
							v-if="currentSection === 3"
							class="ml-10"
						>
							<v-btn
								icon
								color="primary"
								:disabled="updateActive"
								v-on:click.stop="beginUpdate"
								><v-icon>mdi-pencil-outline</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="!dataModified"
								v-on:click.stop="saveChanges"
								color="green"
								><v-icon>mdi-check-outline</v-icon></v-btn
							>
							<v-btn
								icon
								color="red"
								:disabled="!updateActive"
								v-on:click.stop="cancelUpdate"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-card-text>
						<v-row align="center">
							<v-col cols="12" md="2" class="font-weight-medium">
								Video
							</v-col>
							<v-col cols="12" md="10">
								<v-text-field
									v-model="rowObject.video_url"
									:disabled="!updateActive"
									@change="onDataModified"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<!-- DIALOG -->
		<v-dialog v-model="dialog">
			<v-card width="800" max-width="100%" class="mx-auto">
				<v-card-title>
					kies een afbeelding
					<v-spacer />
					<v-btn icon v-on:click="dialog = false"
						><v-icon>mdi-close-circle</v-icon></v-btn
					>
				</v-card-title>
				<v-divider class="mb-4" />
				<v-card-text
					><v-row justify="center">
						<v-col
							cols="auto"
							v-for="(itemImage, imageId) of itemImages"
							:key="imageId"
						>
							<v-card
								outlined
								v-on:click.stop="
									attachImage(
										itemImage.img_url,
										itemImage.img_id
									)
								"
							>
								<v-img
									:src="itemImage.img_tn_url"
									width="150"
									height="150"
									contain
								/>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
	import { mapGetters } from "vuex";
	import { VueEditor } from "vue2-editor";
	import caas from "@/services/caas";
	export default {
		name: "CatalogItemInfo",
		components: { VueEditor },
		data() {
			return {
				dialog: false,
				file: {},
				loading: false,
				currentSection: 0,
				customToolbar: [
					["bold", "italic", "underline"],
					[{ list: "ordered" }, { list: "bullet" }],
					["link"],
				],
				updateActive: false,
				dataModified: false,
				rowObjectBefore: {},
			};
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
				rowObject: "catalogItem/ecatItemInfo",
				itemImages: "catalogItem/ecatItemImg",
			}),
		},
		methods: {
			uploadFile() {
				if (!this.file) return;
				const that = this;
				const fileReader = new FileReader();
				fileReader.onload = function () {
					let params = {};
					params.itemId = 0;
					params.itemSku = "";
					params.fileName = that.file.name;
					params.b64 = fileReader.result.replace(/^data:.+;base64,/, "");
					caas.rpc("createImage", params)
						.then((response) => {
							if (response.data.success) {
								that.rowObject.img_id_3 =
									response.data.success.imageId;
								that.rowObject.img_url_3 =
									response.data.success.imageThumbUrl;
								that.dataModified = true;
							} else if (response.data.error) {
								that.$store.dispatch(
									"session/showBottomMessage",
									response.data.error
								);
							}
						})
						.finally(() => {
							that.file = null;
							that.loading = false;
						});
				};
				this.loading = true;
				fileReader.readAsDataURL(this.file);
			},
			openDialog() {
				if (this.updateActive) {
					this.dialog = true;
				}
			},
			attachImage(pUrl, pId) {
				switch (this.currentSection) {
					case 0:
						this.rowObject.img_url_1 = pUrl;
						this.rowObject.img_id_1 = pId;
						break;
					case 1:
						this.rowObject.img_url_2 = pUrl;
						this.rowObject.img_id_2 = pId;
						break;
					case 2:
						this.rowObject.img_url_3 = pUrl;
						this.rowObject.img_id_3 = pId;
						break;
				}
				this.dialog = false;
			},
			detachImage() {
				if (this.currentSection === 0) {
					this.rowObject.img_url_1 = "";
					this.rowObject.img_id_1 = 0;
				}
				if (this.currentSection === 1) {
					this.rowObject.img_url_2 = "";
					this.rowObject.img_id_2 = 0;
				}
				if (this.currentSection === 2) {
					this.rowObject.img_url_3 = "";
					this.rowObject.img_id_3 = 0;
				}
				this.dialog = false;
			},
			beginUpdate() {
				Object.assign(this.rowObjectBefore, this.rowObject);
				this.rowObjectBefore.RowMod = "";
				this.rowObject.RowMod = "U";
				this.updateActive = true;
			},
			cancelUpdate() {
				this.updateActive = false;
				Object.assign(this.rowObject, this.rowObjectBefore);
				this.dataModified = false;
			},
			onDataModified() {
				this.dataModified = true;
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatItemInfo.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				params.rowObjUpd.push(this.rowObjectBefore);
				params.rowObjUpd.push(this.rowObject);
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.dataModified = false;
							this.updateActive = false;
							this.$store.dispatch("session/showSuccess");
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
		deactivated() {
			this.currentSection = 0;
		},
	};
</script>