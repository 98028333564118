<template>
	<v-card>
		<v-expansion-panels flat accordion v-model="currentSection">
			<!-- ITEM -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Item
						</v-toolbar-title>
						<v-spacer />
						<v-toolbar-items v-if="currentSection === 0">
							<v-btn
								icon
								color="primary"
								:disabled="updateActive"
								v-on:click.stop="beginUpdate"
								><v-icon>mdi-pencil-outline</v-icon>
							</v-btn>
							<v-btn
								icon
								:disabled="!dataModified"
								v-on:click.stop="saveChanges"
								color="green"
								><v-icon>mdi-check-outline</v-icon></v-btn
							>
							<v-btn
								icon
								color="red"
								:disabled="!updateActive"
								v-on:click.stop="cancelUpdate"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-expansion-panel-header>
				<v-divider />
				<v-expansion-panel-content>
					<v-card-text>
						<v-form :readonly="!updateActive">
							<v-row justify="space-between">
								<v-col cols="12" md="6">
									<v-row>
										<v-col>
											<v-text-field
												hide-details="auto"
												v-model="rowObject.item_name"
												label="Naam"
												@input="handleInput"
											/>
										</v-col>
									</v-row>
									<v-row
										><v-col>
											<v-autocomplete
												hide-details="auto"
												v-model="rowObject.brand_id"
												label="Merk"
												:items="brandNames"
												@input="dataModified = true"
										/></v-col>
									</v-row>
									<v-row
										><v-col>
											<v-text-field
												hide-details="auto"
												v-model="
													rowObject.designer_name
												"
												label="Designer"
												@input="handleInput" /></v-col
									></v-row>
								</v-col>
								<v-col cols="6" md="2">
									<v-checkbox
										label="kleuren"
										v-model="rowObject.has_option1"
										@change="dataModified = true"
									/>
									<v-checkbox
										label="maten"
										v-model="rowObject.has_option2"
										@change="dataModified = true"
									/>
									<v-checkbox
										label="varianten"
										v-model="rowObject.has_option3"
										@change="dataModified = true"
									/>
								</v-col>
								<v-col cols="6" md="2">
									<v-checkbox
										label="website"
										v-model="rowObject.is_visible_website"
										@change="dataModified = true"
									/>
									<v-checkbox
										label="nieuw"
										v-model="rowObject.is_new"
										@change="dataModified = true"
									/>
									<v-checkbox
										label="promo"
										v-model="rowObject.is_promo"
										@change="dataModified = true"
									/>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- CATEGORIES -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Categorieën
						</v-toolbar-title>
						<v-spacer />
						<v-toolbar-items v-if="currentSection === 1">
							<v-menu
								:close-on-content-click="closeOnContentClick"
								:z-index="zIndex"
								v-model="showMenu"
								right
								offset-y
								bottom
							>
								<template
									v-slot:activator="{ on: menu, attrs }"
								>
									<v-btn
										v-bind="attrs"
										v-on="{ ...menu }"
										icon
										><v-icon
											>mdi-plus-circle-outline</v-icon
										>
									</v-btn>
								</template>
								<v-card flat>
									<v-card-title>
										<span class="text-subtitle-1"
											>Categorieën</span
										>
										<v-spacer />
										<v-icon @click="showMenu = false"
											>mdi-close-circle-outline</v-icon
										>
									</v-card-title>
									<v-divider />
									<v-treeview
										class="text-caption"
										style="
											max-height: 600px;
											overflow-y: auto;
										"
										selectable
										multiple-active
										hoverable
										dense
										activatable
										open-on-click
										return-object
										selection-type="leaf"
										:items="categoryTV"
										:item-key="categoryTV.id"
										v-model="selectedCategories"
									/>

									<v-divider class="mb-2" />
									<v-card-actions>
										<v-btn
											block
											text
											@click.stop="addToCategory"
											:loading="loading"
											>Toevoegen</v-btn
										>
									</v-card-actions>
								</v-card>
							</v-menu>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-expansion-panel-header>
				<v-divider />
				<v-expansion-panel-content>
					<v-card-text>
						<v-chip
							class="mr-3 mb-3"
							v-for="(category, i) in itemCategories"
							:key="i"
							large
							label
							outlined
							close
							close-icon="mdi-delete-outline"
							@click:close="removeFromCategory(category)"
						>
							<div class="d-flex-column mx-4">
								<div>{{ category.index_name }}</div>
								<div class="text-caption font-weight-light">
									{{ category.dt_created }}
								</div>
							</div>
						</v-chip>
					</v-card-text>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- WORKFLOW -->
			<v-expansion-panel>
				<v-expansion-panel-header :color="bgColor" class="py-0">
					<v-toolbar flat dense color="transparent">
						<v-toolbar-title class="text-overline">
							Workflow
						</v-toolbar-title>
					</v-toolbar>
				</v-expansion-panel-header>
				<v-divider />
				<v-expansion-panel-content>
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="4">
								<v-select
									:items="wfProcessEnum"
									label="Proces"
									v-model="rowObject.wf_process"
									hide-details="auto"
									@change="handleInput()"
									disabled
								/>
							</v-col>
							<v-col cols="12" sm="4">
								<v-select
									:items="wfStatusEnum"
									label="Status"
									v-model="rowObject.wf_status"
									hide-details="auto"
									@change="handleInput()"
									disabled
								/>
							</v-col>
							<v-col cols="12" sm="4">
								<v-text-field
									disabled
									label="Laatst gewijzigd"
									:value="wfLastUpdated"
									hide-details
									flat
									type="DateTime"
								/>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col
								cols="auto"
								v-for="wfAction in wfActionsEnum"
								:key="wfAction.name"
							>
								<v-btn
									text
									plain
									@click="doWfAction(wfAction)"
									>{{ wfAction.name }}</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	import moment from "moment";
	export default {
		name: "CatalogItemItem",
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItem",
				brandNames: "catalogItem/brandNames",
				bgColor: "session/bgColor",
				itemCategories: "catalogItem/itemCategories",
				itemId: "catalogItem/catalogItemId",
			}),
			wfLastUpdated() {
				if (this.rowObject.wf_last_updated) {
					return moment(this.rowObject.wf_last_updated).format(
						"dddd, MMMM Do YYYY, h:mm:ss a"
					);
				}
			},
			wfProcessEnum() {
				return this.$store.getters["catalogItem/wfProcessEnum"];
			},
			wfStatusEnum() {
				return this.$store.getters["catalogItem/wfStatusEnum"](
					this.rowObject.wf_process
				);
			},
			wfActionsEnum() {
				return this.$store.getters["catalogItem/wfActionsEnum"]({
					process: this.rowObject.wf_process,
					status: this.rowObject.wf_status,
				});
			},
		},
		data() {
			return {
				loading: false,
				currentSection: 0,
				closeOnContentClick: false,
				updateActive: false,
				dataModified: false,
				rowObjectBefore: {},
				categoryTV: [],
				selectedCategories: [],
				showMenu: false,
				zIndex: 1,
			};
		},
		methods: {
			doWfAction(wfAction) {
				if (!this.updateActive) this.beginUpdate();
				this.rowObject.wf_process = wfAction.transitions[0].wfProcess;
				this.rowObject.wf_status = wfAction.transitions[0].wfStatus;
				this.saveChanges();
			},
			addToCategory() {
				const sdoName = "web/objects/dECatEntry.r";
				let params = {
					action: "create",
					rowObjUpd: [],
				};
				this.selectedCategories.forEach((category) => {
					let x = {
						RowMod: "A",
						item_id: this.itemId,
						index_id: category.id,
					};
					params.rowObjUpd.push(x);
				});
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("catalogItem/fetchItemCategories");
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.selectedCategories = [];
						this.showMenu = false;
					});
			},
			handleInput() {
				this.dataModified = true;
			},
			beginUpdate() {
				Object.assign(this.rowObjectBefore, this.rowObject);
				this.rowObjectBefore.RowMod = "";
				this.rowObject.RowMod = "U";
				this.updateActive = true;
			},
			cancelUpdate() {
				this.updateActive = false;
				Object.assign(this.rowObject, this.rowObjectBefore);
				this.dataModified = false;
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatItem.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.loading = true;
				params.rowObjUpd.push(this.rowObjectBefore);
				params.rowObjUpd.push(this.rowObject);
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.dataModified = false;
							this.updateActive = false;
							this.$store.dispatch("session/showSuccess");
							this.$store.dispatch("catalogItem/fetchItem");
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			removeFromCategory(_rowObject) {
				const sdoName = "web/objects/dEcatEntry.r";
				const params = {
					action: "delete",
					rowObjUpd: [],
				};
				_rowObject.RowMod = "D";
				params.rowObjUpd.push(_rowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess").then(() => {
								this.$store.dispatch(
									"catalogItem/fetchItemCategories"
								);
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			fetchCategoryTV() {
				let params = { catalogId: 1 };
				return caas
					.rpc("getCategoriesOfCatalog", params)
					.then((response) => {
						this.categoryTV = response.data.success.data.categoryList;
					});
			},
		},
		mounted() {
			this.$store.dispatch("catalogItem/fetchBrands");
			this.fetchCategoryTV();
		},
	};
</script>
