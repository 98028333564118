<template>
	<v-container>
		<v-card min-height="300px">
			<v-toolbar flat dense>
				<v-toolbar-title>
					<span
						>{{ rowObject.item_id }} -
						{{ rowObject.item_name }}</span
					>
					<v-icon
						v-if="!rowObject.is_visible_website"
						class="ml-2 my-auto"
						color="warning"
						>mdi-cloud-off-outline</v-icon
					>
				</v-toolbar-title>
				<v-spacer />
				<v-toolbar-items>
					<v-btn
						icon
						@click="navigateToItem(prevItemId)"
						:disabled="!prevItemId"
						><v-icon>mdi-chevron-left</v-icon></v-btn
					>
					<v-btn
						icon
						@click="navigateToItem(nextItemId)"
						:disabled="!nextItemId"
						><v-icon>mdi-chevron-right</v-icon></v-btn
					>
				</v-toolbar-items>
				<v-toolbar-items>
					<v-btn icon @click.stop="$router.go(-1)"
						><v-icon>mdi-close-circle-outline</v-icon></v-btn
					>
				</v-toolbar-items>
				<template #extension>
					<v-tabs
						v-model="tab"
						grow
						center-active
						show-arrows
						v-on:change="onTabChanged"
					>
						<v-tab v-for="tabItem in tabs" :key="tabItem.id">
							{{ tabItem.label }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
			<v-divider />
			<v-tabs-items v-model="tab" mandatory>
				<v-tab-item :key="0">
					<catalog-item-item />
				</v-tab-item>
				<v-tab-item :key="1">
					<catalog-item-image />
				</v-tab-item>
				<v-tab-item :key="2">
					<catalog-item-info />
				</v-tab-item>
				<v-tab-item :key="3">
					<v-row>
						<v-col cols="12" md="8">
							<catalog-item-attr />
						</v-col>
						<v-col cols="12" md="4">
							<catalog-item-label />
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="4">
					<catalog-item-sku />
				</v-tab-item>
				<v-tab-item :key="5">
					<catalog-item-link />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>
<script>
	import store from "@/store";
	import { mapGetters } from "vuex";
	import CatalogItemItem from "@/components/CatalogItemItem";
	import CatalogItemImage from "@/components/CatalogItemImage";
	import CatalogItemInfo from "@/components/CatalogItemInfo";
	import CatalogItemSku from "@/components/CatalogItemSku";
	import CatalogItemAttr from "@/components/CatalogItemAttr";
	import CatalogItemLabel from "@/components/CatalogItemLabel";
	import CatalogItemLink from "@/components/CatalogItemLink";

	export default {
		name: "CatalogItem",
		components: {
			CatalogItemItem,
			CatalogItemImage,
			CatalogItemInfo,
			CatalogItemSku,
			CatalogItemAttr,
			CatalogItemLabel,
			CatalogItemLink,
		},
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItem",
				prevNextItemId: "catalogItem/prevNextItemId",
				nextItemId: "catalogItem/nextItemId",
				prevItemId: "catalogItem/prevItemId",
				catalogItemId: "catalogItem/catalogItemId",
			}),
		},
		data() {
			return {
				tab: 0,
				tabs: [
					{ id: 0, label: "Item" },
					{ id: 1, label: "Afbeeldingen" },
					{ id: 2, label: "Beschrijving" },
					{ id: 3, label: "Product info" },
					{ id: 4, label: "Bestel info" },
					{ id: 5, label: "Relaties" },
				],
			};
		},
		methods: {
			onTabChanged() {
				switch (this.tab) {
					case 1:
						this.$store.dispatch("catalogItem/fetchItemImages");
						break;
					case 2:
						this.$store.dispatch("catalogItem/fetchItemInfo");
						break;
					case 3:
						this.$store.dispatch("catalogItem/fetchItemLabels");
						this.$store.dispatch("catalogItem/fetchItemAttributes");
						break;
					case 4:
						this.$store.dispatch("catalogItem/fetchItemSkuList");
						break;
					case 5:
						this.$store.dispatch("catalogItem/fetchItemLinks");
						break;
				}
			},
			navigateToItem(item_id) {
				this.$router.replace({
					name: "CatalogItem",
					params: { id: item_id },
				});
				this.$store
					.dispatch("catalogItem/setCatalogItemId", item_id)
					.then(() => {
						this.$store.dispatch("catalogItem/fetchItem").then(() => {
							this.$store
								.dispatch("catalogItem/fetchItemCategories")
								.then(() => {
									if (this.tab > 0) {
										this.onTabChanged();
									}
								});
						});
					});
			},
		},
		beforeRouteEnter(routeTo, routeFrom, next) {
			store
				.dispatch("catalogItem/setCatalogItemId", routeTo.params.id)
				.then(() => {
					store
						.dispatch("catalogItem/fetchItem")
						.then(() => {
							store.dispatch("catalogItem/fetchItemCategories");
						})
						.finally(() => {
							next(); // Only once this is called does the navigation continue
						});
				});
		},
		deactivated() {
			this.tab = 0;
		},
	};
</script>
