<template>
	<v-container>
		<v-row justify="space-around">
			<v-col
				v-for="_rowObject in rowObject"
				:key="_rowObject.RowNum"
				cols="auto"
			>
				<v-card flat width="150">
					<v-card-title>
						<v-sheet outlined>
							<v-img
								position="center center"
								:src="_rowObject.target_item_tn_url"
								width="150"
								aspect-ratio="1"
								contain
							/>
						</v-sheet>
					</v-card-title>
					<v-card-subtitle>
						{{ _rowObject.target_item_id }}
					</v-card-subtitle>
					<v-card-text>
						{{ _rowObject.target_item_name }}
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn icon plain @click="confirmDeleteLink(_rowObject)"
							><v-icon size="20">mdi-delete</v-icon></v-btn
						>
						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-toolbar flat dense :color="bgColor">
				<v-spacer />
				<v-toolbar-items>
					<v-btn icon @click="openDialogAdd()"
						><v-icon>mdi-plus-circle-outline</v-icon></v-btn
					>
				</v-toolbar-items>
				<v-spacer />
			</v-toolbar>
		</v-row>
		<!-- DIALOG ADDLINK -->
		<v-dialog v-model="dialogAdd">
			<v-card
				width="500"
				max-width="100%"
				max-height="100%"
				class="mx-auto"
			>
				<v-card-title>
					nieuwe relatie <v-spacer />
					<v-icon @click="closeDialogAdd()"
						>mdi-close-circle</v-icon
					></v-card-title
				>
				<v-card-subtitle
					>zoek en selecteer een item om te linken</v-card-subtitle
				>
				<v-divider />
				<v-card-text>
					<v-text-field
						v-model="searchText"
						:loading="loading"
						flat
						dense
						hide-details
						full-width
						clearable
						placeholder="item naam"
						append-icon="mdi-magnify"
						@keyup.native.enter="searchItems"
						@click:append="searchItems"
						@click:clear="clearSearch"
					></v-text-field>
					<v-data-table
						height="400"
						fixed-header
						dense
						:headers="headers"
						:items="itemSearch"
						item-key="item_id"
						hide-default-header
						hide-default-footer
						no-data-text=""
						show-select
						disable-pagination
						v-model="targetItems"
					>
						<template v-slot:[`item.is_visible_website`]="{ item }">
							<v-icon
								v-if="!item.is_visible_website"
								color="warning"
								>mdi-cloud-off-outline</v-icon
							>
						</template>
					</v-data-table>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						color="primary"
						:disabled="!targetItems.length"
						@click="addLink()"
					>
						bevestigen
					</v-btn>
					<v-spacer />
					<v-btn text plain @click="closeDialogAdd">
						annuleren
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- DIALOG DELETE -->
		<v-dialog v-model="dialogDelete" width="auto">
			<v-card :loading="loading">
				<v-card-title class="text-subtitle-1">
					{{ currentRowObject.target_item_name }} &nbsp;
					<span>: relatie verwijderen? </span>
				</v-card-title>
				<v-divider />
				<v-card-actions>
					<v-btn text color="primary" @click="deleteLink()"
						>Verwijderen</v-btn
					>
					<v-spacer />
					<v-btn text plain @click="closeDialogDelete()"
						>Annuleren</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";

	export default {
		name: "CatalogItemLink",
		data() {
			return {
				dialogAdd: false,
				dialogDelete: false,
				targetItems: [],
				loading: false,
				currentRowObject: {},
				headers: [
					{ text: "Id", value: "item_id" },
					{ text: "Naam", value: "item_name" },
					{ text: "Website", value: "is_visible_website" },
				],
				searchText: "",
				itemSearch: [],
			};
		},
		computed: {
			...mapGetters({
				rowObject: "catalogItem/ecatItemLink",
				bgColor: "session/bgColor",
				itemId: "catalogItem/catalogItemId",
			}),
		},
		methods: {
			openDialogAdd() {
				this.clearSearch();
				this.dialogAdd = true;
			},
			closeDialogAdd() {
				this.clearSearch();
				this.dialogAdd = false;
			},
			closeDialogDelete() {
				this.dialogDelete = false;
				this.currentRowObject = {};
			},
			clearSearch() {
				this.searchText = "";
				this.itemSearch = [];
				this.targetItems = [];
			},
			searchItems() {
				const sdoName = "web/objects/dECatItem.r";
				const params = {
					action: "read",
					nbrOfRows: 999,
					setOpenQuery:
						"for each ecat_item where item_name contains '" +
						this.searchText +
						"* ' no-lock indexed-reposition",
				};
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.itemSearch = response.data.success.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			addLink() {
				const sdoName = "web/objects/dECatItemLink.r";
				let params = {
					action: "create",
					rowObjUpd: [],
				};
				this.targetItems.forEach((targetItem) => {
					let x = {
						RowMod: "A",
						source_item_id: this.itemId,
						target_item_id: targetItem.item_id,
						link_type: "recommended",
					};
					params.rowObjUpd.push(x);
				});
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("catalogItem/fetchItemLinks");
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.closeDialogAdd();
					});
			},
			confirmDeleteLink(_rowObject) {
				this.currentRowObject = _rowObject;
				this.dialogDelete = true;
			},
			deleteLink() {
				const sdoName = "web/objects/dECatItemLink.r";
				let params = {
					action: "delete",
					rowObjUpd: [],
				};
				this.currentRowObject.RowMod = "D";
				params.rowObjUpd.push(this.currentRowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("catalogItem/fetchItemLinks");
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.closeDialogDelete();
					});
			},
		},
	};
</script>
